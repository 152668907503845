import { useRive } from '@rive-app/react-canvas';
import './App.css';

function App() {
  const { rive, RiveComponent } = useRive({
    src: 'rocket.riv',
    stateMachines: "Rocket",
    autoplay: true,
  });

  return (
    <div class="full-screen"><RiveComponent/></div>
  );
}

export default App;
